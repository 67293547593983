import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [{
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: (resolve) => require(['@/views/redirect'], resolve)
    }]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [{
      path: 'index',
      component: (resolve) => require(['@/views/index'], resolve),
      name: '首页',
      meta: {
        title: '首页',
        icon: 'dashboard',
        noCache: true,
        affix: true
      }
    }]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
      path: 'profile',
      component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
      name: 'Profile',
      meta: {
        title: '个人中心',
        icon: 'user'
      }
    }]
  },
  {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [{
      path: 'type/data/:dictId(\\d+)',
      component: (resolve) => require(['@/views/system/dict/data'], resolve),
      name: 'Data',
      meta: {
        title: '字典数据',
        icon: ''
      }
    }]
  },
  // 关联汽配黄页    我的单位
  {
    path: '/hy',
    component: Layout,
    hidden: true,
    children: [{
      path: 'unitQp/:storeId(\\d+)',
      component: (resolve) => require(['@/views/platform/carrying/index'], resolve),
      name: 'unitQp',
      meta: {
        title: '关联汽配黄页'
      }
    }]
  },
  // 编辑 供应商 自营配件
  {
    path: '/partS_self',
    component: Layout,
    hidden: true,
    children: [{
      path: 'partS_self/:partsId',
      component: (resolve) => require(['@/views/platform/parts/parts/partS_self'], resolve),
      name: 'PartS_self',
      meta: {
        title: '编辑自营配件',
        icon: ''
      }
    }]
  },
  // 编辑 供应商 配件
  {
    path: '/partS_agent',
    component: Layout,
    hidden: true,
    children: [{
      path: 'partS_agent/:partsId',
      component: (resolve) => require(['@/views/platform/parts/parts/partS_agent'], resolve),
      name: 'PartS_agent',
      meta: {
        title: '编辑配件',
        icon: ''
      }
    }]
  },
  // 新增 通用件
  {
    path: '/parts_add',
    component: Layout,
    hidden: true,
    children: [{
      path: 'current',
      component: (resolve) => require(['@/views/platform/parts/parts/parts_add_current'], resolve),
      name: 'Parts_add_current',
      meta: {
        title: '通用配件上架',
        icon: ''
      }
    }]
  },
  // 新增供应商配件
  {
    path: '/partCorpAdd',
    component: Layout,
    hidden: true,
    children: [{
      path: 'current',
      component: (resolve) => require(['@/views/platform/parts/parts/part_corp_add'], resolve),
      name: 'partCorpAdd',
      meta: {
        title: '供应商上架配件',
        icon: ''
      }
    }]
  },
  {
    path: '/job',
    component: Layout,
    hidden: true,
    children: [{
      path: 'log',
      component: (resolve) => require(['@/views/monitor/job/log'], resolve),
      name: 'JobLog',
      meta: {
        title: '调度日志'
      }
    }]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    children: [{
      path: 'index/:tableId(\\d+)',
      component: (resolve) => require(['@/views/tool/gen/editTable'], resolve),
      name: 'GenEdit',
      meta: {
        title: '修改生成配置'
      }
    }]
  },
  {
    path: '/provincePut',
    component: Layout,
    hidden: true,
    forward: 'noforward',
    children: [{
      path: 'provincePutInStore',
      component: (resolve) => require(['@/views/province/order/purchase/putInStore'], resolve),
      name: 'PutInStore',
      meta: {
        title: '入库管理'
      }
    }]
  },
  {
    path: '/purchaseAdd',
    component: Layout,
    hidden: true,
    forward: 'noforward',
    children: [{
        path: 'platformOneKey',
        component: (resolve) => require(['@/views/platform/purchase/add/onekey'], resolve),
        name: 'PurchaseOnekey',
        meta: {
          title: '系统采购',
          noCache: false
        }
      },
      // 铺货的一键采购
      {
        path: 'paveProcurement',
        component: (resolve) => require(['@/views/platform/purchase/add/paveProcurement'], resolve),
        name: 'paveProcurement',
        meta: {
          title: '铺货采购',
          noCache: false
        }
      },
    ]
  },
  {
    path: '/provinceStore',
    component: Layout,
    hidden: true,
    forward: 'noforward',
    children: [{
      path: 'provincePutInStore',
      component: (resolve) => require(['@/views/province/store/shelf/index'], resolve),
      name: 'PutInStore',
      meta: {
        title: '入库管理'
      }
    }]
  },
  {
    path: '/purchase',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
      path: 'purchase/provinceFastPurchase',
      component: (resolve) => require(['@/views/province/purchase/purchase/fastPurchase1'], resolve),
      name: 'ProvinceFastPurchase',
      meta: {
        title: '一键采购'
      }
    }]
  },
  {
    path: '/print/:secretKey',
    component: (resolve) => require(['@/views/print/pdf'], resolve),
    hidden: true
  }
]

export default new Router({
  mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})