<template>
  <!-- 适用车型搜索框 -->
  <el-autocomplete v-model="modelName" :fetch-suggestions="queryModel" placeholder="输入可模糊查询"
    @select="handleSelectModel">
  </el-autocomplete>
</template>

<script>
  import {
    selectLikeModelName
  } from "@/api/wms/vehicle_model";
  export default {
    name: "VehicleModel",
    emits: ["select"],
    props: {
      value: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        modelName: this.value
      };
    },
    watch: {
      value: function() {
        this.modelName = this.value
      }
    },
    methods: {
      queryModel(modelName = '', cb) { // 默认车型 模糊查询
        selectLikeModelName({
          modelName: modelName,
          pageNum: 1,
          pageSize: 100,
        }).then((res) => {
          let data = res.rows.map((item) => ({
            value: (item.automakerName || '') + item.modelName,
            label: item.modelName,
            id: item.id
          }))
          cb(data)
        });
      },
      handleSelectModel(item) {
        this.$emit("select", item)
        this.$emit('input', item.value)
      }
    }
  };
</script>

<style>
</style>