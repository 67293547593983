var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    attrs: {
      "fetch-suggestions": _vm.queryModel,
      placeholder: "输入可模糊查询",
    },
    on: { select: _vm.handleSelectModel },
    model: {
      value: _vm.modelName,
      callback: function ($$v) {
        _vm.modelName = $$v
      },
      expression: "modelName",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }