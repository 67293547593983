import request from '@/utils/request'

// 查询消息通知详细
export function quotationList() {
  return request({
    url: '/wms/notify/quotationList',
    method: 'get'
  })
}

// 添加语音播报记录
export function addNotify(data) {
  return request({
    url: '/wms/notify',
    method: 'post',
    data: data
  })
}