<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import {
    quotationList,
    addNotify
  } from "@/api/wms/notify";
  import {
    checkPermi
  } from "@/utils/permission";

  const synth = window.speechSynthesis;
  const msg = new SpeechSynthesisUtterance();
  const music = new Audio('https://yanshi-oss.oss-cn-hangzhou.aliyuncs.com/mp3/quotation.mp3')
  export default {
    name: 'App',
    data() {
      return {
        timer: "",
        value: 0,
      };
    },
    created() {
      let url = window.location.href;
      if (process.env.NODE_ENV !== 'development') {
        // 强制https
        if (url.indexOf("https:") < 0) {
          url = url.replace("http:", "https:");
          return window.location.replace(url);
        }
        // 强制新域名
        if (url.indexOf("https://qkjf.ysshssyc.com") == 0) {
          url = url.replace("https://qkjf.ysshssyc.com", "https://www.qkjfvip.com");
          return window.location.replace(url);
        }
        // 强制www
        if (url.indexOf("https://qkjfvip.com") == 0) {
          url = url.replace("https://qkjfvip.com", "https://www.qkjfvip.com");
          return window.location.replace(url);
        }
      }
    },
    metaInfo() {
      return {
        title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
        titleTemplate: title => {
          return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
        }
      }
    },
    mounted() {
      this.start();
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    methods: {
      start() {
        this.timer = setInterval(this.getNoticeList, 5000); // 注意: 第一个参数为方法名的时候不要加括号;
      },
      getNoticeList() {
        let flag = checkPermi(["wms:quotation:list"]);
        if (flag) {
          quotationList().then(res => {
            if (res.data > 0) {
              addNotify({
                notifyType: 1,
                bizId: res.data
              }).then(res => {
                music.volume = 1
                music.play()
              })
            }
          })
        } else {
          clearInterval(this.timer);
        }
      },
      /** 语音播报的函数*/
      handleSpeak(e) {
        console.log(e);
        msg.text = e;
        msg.lang = 'zh-CN';
        msg.volume = '1';
        msg.rate = 1;
        msg.pitch = 1;
        synth.speak(msg);
      },
      /* * 语音停止  */
      handleStop(e) {
        msg.text = e;
        msg.lang = 'zh-CN';
        synth.cancel(msg);
      }
    },
  }
</script>
<style rel="stylesheet/scss" lang="scss">
  .el-table tbody tr:hover>td {
    background-color: transparent !important;
  }

  .el-table__body tr.current-row>td.el-table__cell {
    background: #6feb95 !important
  }

  .el-table th.el-table__cell.is-leaf,
  .el-table td.el-table__cell {
    border-bottom: 1px solid #AEAEAE;
  }

  .el-table--border .el-table__cell {
    border-right: 1px solid #AEAEAE;
  }

  .el-table .el-table__header-wrapper th,
  .el-table .el-table__fixed-header-wrapper th {
    background-color: #E2E2E2;
  }

  .el-autocomplete-suggestion li.highlighted {
    background-color: #6feb95;
  }

  .el-autocomplete-suggestion li:hover {
    background-color: #b1a74f;
  }

  body .el-table th.gutter {
    display: table-cell !important;
  }
</style>