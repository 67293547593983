import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  return request({
    url: '/ucenter/user/login',
    method: 'post',
    data: {
      username,
      password,
      code,
      uuid
    }
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/wms/user/info',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/ucenter/user/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/ucenter/code',
    method: 'get'
  })
}

// 切换公司
export function switchCompany(id) {
  return request({
    url: '/wms/user/switch/' + id,
    method: 'put'
  })
}
