<template>
  <!-- 通用表格 -->
  <div class="commonTable" v-loading="loading" element-loading-text="拼命加载中">
    <div :style="option.pageType ? 'padding-bottom: 10px' : 'padding-bottom: 80px'">
      <!-- 顶部盒子（用于存放各种操作按钮） -->
      <div class="topBox">
        <div class="left">
          <!-- 表格上方的操作按钮 -->
          <slot name="top" :selectionData="selectionData"></slot>
        </div>
        <div class="right">
          <el-tooltip class="item" effect="dark" content="刷新" placement="top">
            <!-- 刷新按钮 -->
            <el-button size="mini" circle icon="el-icon-refresh" @click="getData()" />
          </el-tooltip>
        </div>
      </div>
      <el-table :data="tableData" stripe border @selection-change="selectionChange" highlight-current-row ref="tableRef"
        @current-change="currentChange" style="width: 100%">
        <!-- 多选框 默认不开启 -->
        <el-table-column type="selection" width="50" align="center" v-if="option.needSelection"
          :selectable="option.selectable || null" />
        <!-- 循环生成表格项 -->
        <template v-for="(item, index) in option.column">
          <!-- 使用具名插槽自定义内容 -->
          <el-table-column :prop="item.prop" :label="item.label" v-if="item.slot && item.type != 'hide'" :key="index"
            align="center" :width="item.width">
            <template slot-scope="scope">
              <slot :name="item.prop" :data="scope.row" class="slot-class"></slot>
            </template>
          </el-table-column>
          <!-- -------- 非插槽 start -------- -->
          <template v-else>
            <!-- 普通 -->
            <el-table-column v-if="!item.type && item.type != 'hide'" :prop="item.prop" :label="item.label" :key="index"
              :formatter="item.formatter || publicFormatter" align="center" :width="item.width"></el-table-column>
            <!-- 展开行 -->
            <el-table-column v-if="item.type == 'expand'" type="expand" label="更多信息" width="75" :key="index">
              <template slot-scope="scope">
                <slot :name="item.prop" :data="scope.row"></slot>
              </template>
            </el-table-column>
            <!-- 时间 -->
            <el-table-column v-if="item.type == 'time'" :label="item.label" align="center" :key="index"
              :width="item.width">
              <template slot-scope="scope">
                <div>{{ scope.row[item.prop][0] }}</div>
                <div>{{ scope.row[item.prop][1] }}</div>
              </template>
            </el-table-column>
          </template>
          <!-- -------- 非插槽 end -------- -->
        </template>
      </el-table>
    </div>
    <!-- 分页 -->
    <div :class="option.pageType ? option.pageType + 'PageBox' : 'pageBox'">
      <el-pagination ref="pageRef" background layout="total, sizes, prev, pager, next, jumper"
        :current-page="pageParam.pageNum" :total="total" @size-change="sizeChange" @current-change="pageNumChange"
        :pager-count="7">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import request from "@/utils/request";

  export default {
    data() {
      return {
        loading: false, // loading开关
        tableData: [], // 表格数据
        total: 0,
        // 分页参数
        pageParam: {
          pageNum: 1,
          pageSize: 10,
        },
        // 公共的内容格式化
        publicFormatter: (row, column, value) => {
          return value === null || value === undefined ? "-" : value;
        },
        selectionData: [], // 多选框的数据
        currentRow_index: -1, // 当前选中行的索引
        currentData: null, // 当前选中行的数据
      };
    },
    props: {
      // 表格配置项
      option: {
        type: Object,
      },
      // 请求参数
      queryParam: {
        type: Object,
      },
    },
    watch: {
      currentRow_index(val) {
        if (val == -1) {
          this.currentData = null
        } else {
          this.currentData = this.$refs['tableRef'].data[val]
        }
      }
    },
    created() {
      this.getData();
    },
    methods: {
      // 获取数据
      getData(param = {}) {
        if (this.loading) {
          return // 禁止重复提交
        }
        // 判断是否有参数
        if (param.pageNum) {
          this.$set(this.pageParam, "pageNum", param.pageNum);
        }
        let queryParam = JSON.parse(JSON.stringify(this.queryParam))
        // 处理请求参数
        if (this.option.dealQueryParam) {
          queryParam = this.option.dealQueryParam(queryParam);
        }
        // 去除OE号中的横杠
        if (queryParam.figureNum) {
          queryParam.figureNum = queryParam.figureNum.replace(/-/g, "")
        }
        this.loading = true;
        request({
            url: this.option.url,
            method: this.option.method || "get", // 默认是 get 请求
            params: {
              ...queryParam,
              ...this.pageParam
            },
          })
          .then((res) => {
            if (res.code == 200) {
              // 是否需要处理数据结构
              if (this.option.dealFormation) {
                res = this.option.dealFormation(res);
              }
              // 处理表格数据
              if (this.option.editData) {
                let data = this.option.dealData ? this.option.dealData(res.rows) : res.rows;
                let rows = data.map(i => {
                  i.show = true
                  i.autofocus = false
                  return i
                })
                this.tableData = JSON.parse(JSON.stringify(rows));
              } else {
                this.tableData = this.option.dealData ? this.option.dealData(res.rows) : res.rows;
              }
              this.total = res.total;
              this.currentRow_index = -1
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      // 多选框变化
      selectionChange(selection) {
        this.selectionData = selection;
      },
      // 每页条数 发生变化
      sizeChange(size) {
        this.$set(this.pageParam, "pageNum", 1);
        this.$set(this.pageParam, "pageSize", size);
        this.getData();
      },
      // 页码 发生变化
      pageNumChange(num) {
        this.$set(this.pageParam, "pageNum", num);
        this.getData();
      },
      // 当前选中行发生变化时 触发
      currentChange(currentRow) {
        this.currentRow_index = this.tableData.findIndex((item) => {
          return item == currentRow
        })
      },
      /**
       * 设置表格选中行
       * @String类型的参数 up向上 down向下
       * @Number类型的参数 index索引 指定具体哪一行
       */
      setCurrentRow(param) {
        if (this.currentRow_index == -1) {
          this.currentRow_index = 0
          this.setCurrent()
        } else {
          switch (param) {
            case 'up':
              if (this.currentRow_index > 0) this.currentRow_index -= 1
              break;
            case 'down':
              if ((this.tableData.length - 1) > this.currentRow_index) this.currentRow_index += 1
              break;
          }
          this.setCurrent()
        }
        if (this.option.editData) {
          let data = this.$refs['tableRef'].data[this.currentRow_index];
          this.$emit("focusInput", data)
        }
      },
      // 设置选中行
      setCurrent() {
        if (this.currentRow_index != -1) {
          this.$refs['tableRef'].setCurrentRow(this.$refs['tableRef'].data[this.currentRow_index])
        }
      },
      // 设置展开行
      setExpansion() {
        if (this.currentRow_index != -1) {
          this.$refs['tableRef'].toggleRowExpansion(this.currentData)
        }
      },
      // 切换分页 left加 right减
      changePage(param) {
        let num = this.pageParam.pageNum // 当前页码
        switch (param) {
          case 'left':
            if (this.pageParam.pageNum != 1) {
              this.$set(this.pageParam, "pageNum", num - 1);
              this.getData();
            }
            break;
          case 'right':
            if (this.pageParam.pageNum != this.$refs['pageRef'].internalPageCount) {
              this.$set(this.pageParam, "pageNum", num + 1);
              this.getData();
            }
            break;
        }
      },
      // 用于多选表格，切换某一行的选中状态
      toggleRowSelection() {
        if (this.currentRow_index != -1) {
          this.$refs['tableRef'].toggleRowSelection(this.currentData)
        }
      },
    },
  };
</script>


<style lang="scss" scoped>
  .commonTable {
    position: relative;

    .slot-class {
      word-wrap: break-word;
      white-space: pre-wrap;
    }

    .topBox {
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
      }
    }

    .pageBox {
      position: fixed;
      bottom: 30px;
      right: 100px;
      background: #fff;
      border-radius: 20px;
      padding: 10px 20px;
      box-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
      z-index: 1000;
    }

    // 分页位于顶部
    .topPageBox {
      position: absolute;
      top: 0;
      left: 0;
    }

    .bottomPageBox {
      position: relative;
    }
  }
</style>