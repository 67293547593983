// 按键操作
class buttonOpt {
    static $vue = null
    static pause = false // 是否暂停监听
    static keydown_backCall = null // 键盘事件 回调
    static noShopCode = ['ControlLeft', 'KeyC', 'F5', 'Tab'] // 不用阻止默认事件的按键

    constructor(vue) {
        buttonOpt.$vue = vue
    }

    // 设置监听事件
    setOpt(_this) {
        buttonOpt.pause = false
        // 如果已有监听 就先清除掉
        if (buttonOpt.keydown_backCall !== null) document.removeEventListener('keydown', buttonOpt.keydown_backCall)
        buttonOpt.keydown_backCall = (e) => {
            if (!buttonOpt.noShopCode.includes(e.code) && document.activeElement.tagName != 'INPUT') {
                e.preventDefault() // 阻止默认事件
                _this.buttonOpts(e.code)
            }
        }
        document.addEventListener('keydown', buttonOpt.keydown_backCall)
    }

    // 设置是否暂停
    setPause(blur) {
        buttonOpt.pause = blur
        switch (blur) {
            case false:
                document.addEventListener('keydown', buttonOpt.keydown_backCall)
                break;
            case true:
                document.removeEventListener('keydown', buttonOpt.keydown_backCall)
                break;
        }
    }

    // 开启按键切换 tab 菜单
    changeTabMenu() {
        document.addEventListener('keydown', function (e) {
            if (!buttonOpt.noShopCode.includes(e.code) && document.activeElement.tagName != 'INPUT' &&
                (e.code == 'Comma' || e.code == 'Period')
            ) {
                let max = buttonOpt.$vue.$store.state.tagsView.visitedViews.length // 目前菜单的长度
                let index = buttonOpt.$vue.$store.state.tagsView.visitedViews.findIndex((item) => {
                    return item.path == buttonOpt.$vue.$route.path
                })
                switch (e.code) {
                    case 'Comma': // 向左
                        if (index > 0) {
                            var { path, query, fullPath } = buttonOpt.$vue.$store.state.tagsView.visitedViews[index - 1]
                            buttonOpt.$vue.$router.push({
                                path, query, fullPath
                            })
                        }
                        break;
                    case 'Period': // 向右
                        if (index < max - 1) {
                            var { path, query, fullPath } = buttonOpt.$vue.$store.state.tagsView.visitedViews[index + 1]
                            buttonOpt.$vue.$router.push({
                                path, query, fullPath
                            })
                        }
                        break;
                }
            }
        })
    }
}

export default buttonOpt