var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination-container", class: { hidden: _vm.hidden } },
    [
      _c(
        "el-pagination",
        _vm._b(
          {
            attrs: {
              background: _vm.background,
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
              layout: _vm.layout,
              "page-sizes": _vm.pageSizes,
              "pager-count": _vm.pagerCount,
              total: _vm.total,
              small: _vm.small,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function ($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function ($event) {
                _vm.pageSize = $event
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
            nativeOn: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  ) {
                    return null
                  }
                  if ("button" in $event && $event.button !== 0) {
                    return null
                  }
                  return _vm.handleLeft($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "right", 39, $event.key, [
                      "Right",
                      "ArrowRight",
                    ])
                  ) {
                    return null
                  }
                  if ("button" in $event && $event.button !== 2) {
                    return null
                  }
                  return _vm.handleRight($event)
                },
              ],
            },
          },
          "el-pagination",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }