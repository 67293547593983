// 全屏 loading
class fullscreenLoading {
    static $vue = null
    static loadingInstance = null

    constructor(vue) {
        this.$vue = vue
    }

    open(text) { // 开启
        this.loadingInstance = this.$vue.$loading({
            lock: true,
            text: text || '疯狂加载中',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    close() { // 关闭
        this.loadingInstance.close()
        this.loadingInstance = null
    }
}

export default fullscreenLoading