import request from '@/utils/request'

// 查询字典数据列表
export function listData(query) {
  return request({
    url: '/wms/system/dict/data/list',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictCode) {
  return request({
    url: '/wms/system/dict/data/' + dictCode,
    method: 'get'
  })
}

// 获取字典数据 返回数据为对象 {0: '待审核', 1: '在售', 2: '下架'}
export async function getDictTwo(dictType) {
  let { data } = await request({
    url: '/wms/system/dict/data/type/' + dictType,
    method: 'get'
  })
  let obj = {}
  data.forEach(item => {
    obj[item.dictValue] = item.dictLabel
  });
  return obj
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/wms/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

// 新增字典数据
export function addData(data) {
  return request({
    url: '/wms/system/dict/data',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data) {
  return request({
    url: '/wms/system/dict/data',
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData(dictCode) {
  return request({
    url: '/wms/system/dict/data/' + dictCode,
    method: 'delete'
  })
}

// 导出字典数据
export function exportData(query) {
  return request({
    url: '/wms/system/dict/data/export',
    method: 'get',
    params: query
  })
}
