import Vue from 'vue'
import Cookies from 'js-cookie'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins

import {
  download
} from '@/utils/request'

import './assets/icons' // icon
import './permission' // permission control
import {
  getDicts,
  getDictTwo
} from "@/api/system/dict/data";
import {
  getConfigKey
} from "@/api/system/config";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  handleTree,
  qqMap
} from "@/utils/ruoyi";
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 字典标签组件
import DictTag from '@/components/DictTag'
// 头部标签组件
import VueMeta from 'vue-meta'
//vue 针试打印机实现
// import Print from 'vue-print-nb'
// import Print from '@/assets/print'
 import Print from './assets/print'

// 通用表格
import commonTable from "@/components/Bo/commonTable.vue"
// 车型的搜索框的组件
import vehicleModel from "@/components/Bo/vehicleModel.vue"

import http from '/src/utils/request'

import './utils/click.js'
// 字典数据组件
import DictData from '@/components/DictData'

// 自定义工具类
import buttonOpt from './utils/view/buttonOpt'
import fullscreenLoading from './utils/view/fullscreenLoading'

// lodash
import _ from 'lodash'
Vue.prototype._ = _
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getDictTwo = getDictTwo
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.qqMap = qqMap
Vue.prototype.$http = http

Vue.prototype.msgSuccess = function(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
}

Vue.prototype.msgError = function(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
}

Vue.prototype.msgInfo = function(msg) {
  this.$message.info(msg);
}

// el-dialog 全局设置为当鼠标点击弹窗外时，不关闭弹窗
Element.Dialog.props.closeOnClickModal.default = false
// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('commonTable', commonTable) // 通用表格
Vue.component('vehicleModel', vehicleModel)

Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
Vue.use(Print)
DictData.install()

Vue.use(Element, {
  size: Cookies.get('size') || 'mini' // set element-ui default size
})

Vue.config.productionTip = false

const vue = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

Vue.prototype.$buttonOpt = new buttonOpt(vue)
Vue.prototype.$fullscreenLoading = new fullscreenLoading(vue)
