<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }" :style="{
    backgroundColor:
      sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg,
  }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo collapse-logo" />
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImg from "@/assets/logo/syc.png";
import variables from "@/assets/styles/variables.scss";

export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme;
    },
  },
  data() {
    return {
      title: "",
      logo: logoImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  padding-top: 10px;
  width: 100%;
  height: 110px;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    & .sidebar-logo {
      width: 72px;
      height: 100px;
      vertical-align: middle;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }

    // 折叠后的logo
    & img.collapse-logo {
      width: 36px;
      height: 50px;
    }
  }

  &.collapse {
    height: 60px !important;

    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>